html, #root {
    height: 100%; /* needed to maintain viewport height on mobile */
    overflow-x: hidden;
    overflow-y: hidden;
    touch-action: manipulation;
}

body {
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    margin: 0;
    font-family: 'IBM Plex Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
  touch-action: manipulation;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
img[src=""] { display: none; }

img {
    touch-action: manipulation;
}