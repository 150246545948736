.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  flex: 1;
  height: 100%;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent; /*prevents flicker*/
}

.App-link {
  color: #61dafb;
}

/* LifeCard */

.Lifecard-Top {
    background-color: #1B262C;
    background-image: url('./assets/ikoria_bg.png');
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 5px;
    flex: 1;
    position: relative;
    transform: rotate(-180deg);
}

.Lifecard-Bottom {
    background-color: #0F4C75;
    background-image: url('./assets/heliod_bg.png');
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 5px;
    flex: 1;
    position: relative;
}


.life-total {
    color: #fcfcfc;
    font-size: 80pt;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lifebutton-container {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: transparent;
    border-radius: 5px;
    border: none;
}

.transparent-button {
    position: relative;
    display: flex;
    width: 50%;
    height: 100%;
    background-color: transparent;
    border: none;
}

.transparent-button:active {
    background-color: white;
    opacity: 0.05;
}

/* Center Console */

.CenterConsole {
    display: flex;
    align-items: 'center';
    justify-content: 'center';
    flex-direction: row;
    flex: 0.3;
    margin-top: 10px;
    margin-bottom: 10px;
}

.console-button-left {
    border-radius: 5px;
    flex: 1;
    display: flex;
    background-color: transparent;
    color: #fcfcfc;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    font-family: 'Roboto';
    font-size: 12pt;
    border: none;
}

.console-button-left:active {
    background-color: #1f1f1f
}

.console-button-right {
    border-radius: 5px;
    flex: 1;
    display: flex;
    background-color: transparent;
    color: #fcfcfc;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    font-family: 'Roboto';
    font-size: 12pt;
    border: none;
}

.console-button-right:active {
    background-color: #1f1f1f
}

.console-button-center {
    border-radius: 5px;
    flex: 1;
    display: flex;
    background-color: transparent;
    color: #fcfcfc;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    font-family: 'Roboto';
    font-size: 12pt;
    border: none;
}

.console-button-center:active {
    background-color: #1f1f1f
}

/* Price Modal */

.price-modal {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: #121212;
    z-index: 1;
    flex-direction: column;
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

#backButtonID {
    display: flex;
    padding-bottom: 16px;
    padding-left: 10px;
    padding-top: 10px;
}

#cardSearchForm {
    display: flex;
    padding-left: 10px;
}

#cardSearchLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#cardSearchIcon {
    margin-right: 10px;
}

#cardSearchField {
    height: 100%;
    background-color: transparent;
    border: none;
    font-size: 16pt;
    color: white;
    outline: none;
    font-family: 'IBM Plex Mono', monospace;
}

::placeholder {
    color: rgb(88, 88, 88);
}

.image-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 400px;
    justify-content: center;
    position: relative;

}

#cardAutocomplete {
    color: white;
    text-align: left;
    list-style-type: none;
    font-size: 16pt;
    margin-top: 10px;
    margin-bottom: 8px;
    padding-left: 10px;
}

#autocompleteList {
    display: flex;
    flex-direction: column;
    margin-left: 52px;
}

.foil-toggle-off {
    border-radius: 30px;
    color: white;
    border-color: white;
    border: solid;
    width: 60px;
    border-width: 2px;
    display: flex; 
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    padding-bottom: 2px;
}

.foil-toggle-on {
    border-radius: 30px;
    color: white;
    border-color: white;
    background-color: #BA9D05;
    border: solid;
    width: 60px;
    border-width: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    padding-bottom: 2px;
}

.add-to-list-button {
    border-radius: 30px;
    flex: 1;
    display: flex;
    background-color: transparent;
    color: #fcfcfc;
    justify-content: center;
    align-items: center;
    font-family: 'IBM Plex Mono', monospace;
    font-size: 12pt;
    border: solid;
    border-color: white;
    border-width: 2px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.card-button-container {
    display: flex;
    margin-top: 20px;
}

.value-counter {
    color: white;
    font-size: 40pt;
    display: flex;
    margin-left: 10px;
    margin-bottom: 20px;
}

.dollar-sign {
    font-size: 18pt;
    margin-right: 10px;
}

/* Trainer */

.small-training-set-button {
    border: none;
    border-radius: 50px;
    width: 100px;
    text-align: center;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    height: 50px;
}

.set-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;  
}

.set-buttons-row {
    display: flex;
    flex-direction: row;
}

.large-training-set-button {
    border: none;
    border-radius: 50px;
    width: 220px;
    text-align: center;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    height: 50px;
}

.quiz-modal {    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: #121212;
    z-index: 2;
    flex-direction: column;
    align-items: center;
}

.blur-box {
    height: 37%;
    width: 100%;
    display: flex;
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.3);
}

.next-button {
    border: solid;
    border-radius: 50px;
    width: 120px;
    text-align: center;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 14pt;
    font-family: 'IBM Plex Mono';
    background-color: transparent;
    margin-top: 20px;
}

.finish-button {
    border: solid;
    border-radius: 50px;
    width: 120px;
    text-align: center;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 14pt;
    font-family: 'IBM Plex Mono';
    background-color: #BA9D05;
    margin-top: 20px;
}
